/** @format */

import { GETCallHelper } from "../../../services";
import { handleError } from "../../../errorServices";

export default {
  async loadStaffGroup() {
    try {
      const response = await GETCallHelper(`/ma/complaints/staff-groups`);
      return response.data["complaint-staff-groups"];
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async loadSubjectArea() {
    try {
      const response = await GETCallHelper(`/ma/complaints/subjects`);
      return response.data["complaint-subjects"];
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchAllComplaints({ commit }, payload) {
    try {
      let url = `/ma/complaints?page=${payload.page}&practice=${payload.practice}`;
      if (payload.status >= 0) {
        url += `&status=${payload.status}`;
      }

      if (payload.filters) {
        if (payload.filters.subjectAreaFilters.length !== 0) {
          url += `&subject=${payload.filters.subjectAreaFilters}`;
        }
        if (payload.filters.staffGroupFilters.length !== 0) {
          url += `&staffGroup=${payload.filters.staffGroupFilters}`;
        }
        if (payload.filters.patientAgeGroupFilters.length !== 0) {
          url += `&agegroup=${payload.filters.patientAgeGroupFilters}`;
        }
        if (payload.filters.dateFilters.length !== 0) {
          url += `&startDate=${payload.filters.dateFilters[0].startDate}&endDate=${payload.filters.dateFilters[0].endDate}`;
        }
      }
      if (payload.search) {
        url += `&search=${payload.search}`;
      }
      const response = await GETCallHelper(url);
      commit("setAllComplaints", response.data["complaints"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchSingleComplaint({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/ma/complaints/complaint?complaint=${payload.complaintId}&practice=${payload.practice}`
      );

      commit("setComplaint", response.data["complaint"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchComplaintsTypeStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/ma/complaints/complaints-count?practice=${payload.practice}`
      );

      commit("setComplaintTypeStats", response.data);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchSubjectGroupStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/ma/complaints/subject-group-complaints-count?filter=${payload.filter}&practice=${payload.practice} `
      );

      commit("setSubjectGroupStats", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchStaffGroupStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/ma/complaints/staff-group-complaints-count?filter=${payload.filter}&practice=${payload.practice} `
      );

      commit("setStaffGroupStats", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchAgeGroupStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/ma/complaints/age-group-complaints-count?filter=${payload.filter}&practice=${payload.practice} `
      );

      commit("setAgeGroupStats", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchPatientRelationshipGroupStats({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/ma/complaints/complainant-relationship-count?filter=${payload.filter}&practice=${payload.practice} `
      );

      commit("setPatientRelationshipGroupStats", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },
};
