/** @format */

export default {
  getMedicalLettersRequests(state) {
    return state.medicalLetterRequests;
  },
  getMedicalLetterRequest(state) {
    return state.medicalLetterRequest;
  },
  getSignature(state) {
    return state.signature;
  },
};
