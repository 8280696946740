/** @format */

// Return user names as Initials
// let getInitials = function(name) {
//   let parts = name.split(" ");
//   let initials = "";
//   for (let i = 0; i < parts.length; i++) {
//     if (parts[i].length > 0 && parts[i] !== "") {
//       initials += parts[i][0];
//     }
//   }
//   return initials;
// };
// Log user in & store token in localStorage & store other data in VUEX
export async function loginService(context, response, token) {
  context.commit("setIsLoggedIn", true);
  await context.commit("setUser", response.user);

  if (token) {
    const time = new Date().toLocaleTimeString();
    localStorage.setItem("token", response.user.token);
    localStorage.setItem("loginTime", time);
    localStorage.setItem("user_id", response.user.id);
    context.commit("setToken", response.user.token);
  } else {
    context.commit("setToken", response.user.token);
  }

  if (response.user.practices.length > 1) {
    context.commit("setPractices", response.user.practices);
  } else {
    localStorage.setItem("practice", response.user.practices[0]?.id);
    localStorage.setItem(
      "practice_name",
      response.user.practices[0]?.practice_name
    );
    localStorage.setItem(
      "assigned_Practices",
      response.user?.practices?.length
    );
    localStorage.setItem("region", response.user.practices[0]?.region);
    context.commit("setPractices", response.user.practices[0]);
  }
  localStorage.setItem("role", response.user.roles[0].name);
  await context.commit("setRoleId", response.user.roles[0].name);
  // localStorage.setItem("initials", getInitials(response.user.name));
}
// Clear local & VUEX storage and log user out
export function clearAuth(context) {
  localStorage.clear();
  context.commit("setIsLoggedIn", false);
  context.commit("setUser", {});
  context.commit("setToken", null);
  context.commit("setRoleId", null);
  context.commit("setPractices", null);
}
