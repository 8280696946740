/** @format */

import {
  GETCallHelper,
  PUTCallHelper,
  POSTCallHelper,
  DELETECallHelper,
} from "../../../services";
import { handleError } from "../../../errorServices";

export default {
  async fetchModelDayActivities({ commit }, payload) {
    try {
      let url = `/hq/model-day-shifts/get-activities?shift=${
        payload.shift
      }&region[]=${payload.region}${
        payload.page ? `&page=${payload.page}` : ""
      }${payload.date ? `&filter_date=${payload.date}` : ""}`;
      const response = await GETCallHelper(url);
      commit("setActivities", response.data["activities"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchInstanceRequests({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/model-day-shifts/instances/requests?shift=${payload.shift}&page=${payload.page}&region=${payload.region}`
      );
      commit("setInstanceRequests", response.data["instance-requests"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchInstanceSubmissions({ commit }, payload) {
    try {
      if (payload.date) {
        const response = await GETCallHelper(
          `/hq/model-day-shifts/instances/submissions?shift=${payload.shift}&status=${payload.status}&page=${payload.page}&date=${payload.date}&region=${payload.region}`
        );
        commit("setInstanceSubmissions", response.data["submissions"]);
      } else {
        const response = await GETCallHelper(
          `/hq/model-day-shifts/instances/submissions?shift=${payload.shift}&status=${payload.status}&page=${payload.page}&region=${payload.region}`
        );
        commit("setInstanceSubmissions", response.data["submissions"]);
      }
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async updateInstanceRequestStatus({ commit }, payload) {
    try {
      const response = await PUTCallHelper(
        `/hq/model-day-shifts/instances/requests/update-status?instance_request=${payload.instance_request}&status=${payload.status}`
      );
      if (response.data.success) {
        commit("updateInstanceRequestStatus", {
          response: response.data["instance-request"].status,
          instanceId: payload.instance_request,
        });
      }
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchInstanceCount({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/model-day-shifts/instances/count?shift=${payload.shift}&date=${payload.date}&region=${payload.region}`
      );
      commit("setInstanceCount", response.data["instances-count"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchSingleInstance({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/model-day-shifts/instances/single-instance?instance=${payload.instanceId}&shift=${payload.shiftId}&practice=${payload.practiceId}`
      );

      commit("setSingleInstance", response.data["instance"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchThresholds({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        "/hq/model-day-shifts/get-thresholds",
        payload
      );
      commit("setThresholds", response.data["thresholds"]);
      return response.data["thresholds"];
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async updateThresholds(_, payload) {
    try {
      const response = await PUTCallHelper(
        "/hq/model-day-shifts/update-thresholds",
        payload
      );
      return response;
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async loadPractices({ commit }, payload) {
    try {
      let url = "hq/practices";
      if (payload) {
        url += `?region=${payload}`;
      }
      const response = await GETCallHelper(url);
      commit("setPractices", response.data.practices);
      return response.data.practices;
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async addQuestions({ commit }, payload) {
    try {
      const response = await POSTCallHelper(
        "/hq/model-day-shifts/create-activities",
        payload.payload
      );
      if (!payload?.isNew) commit("addActivities", response?.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async deleteActivity(_, payload) {
    try {
      const response = await DELETECallHelper(
        `/hq/model-day-shifts/delete-activities?activity=`,
        payload.activityId
      );
      return response;
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchSingleActivity({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/model-day-shifts/single-activity?activity=${payload.activityId}&shift=${payload.shift}`
      );
      commit("setSingleActivity", response.data["data"]);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  /**
   *---------------------------------
   * Hq Dashboard Model Day Endpoint
   *---------------------------------
   */

  async fetchDashInstanceCount({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/hq/model-day-shifts/instances/dashboard-count?region=${payload.region}`
      );
      commit("setDashInstanceCount", response.data);
    } catch (e) {
      handleError(e.response.data);
    }
  },
};
