/** @format */

import { createStore } from "vuex";

import hqQof from "./modules/HQ/MoPF/QOF";
import maQof from "./modules/MA/MOPF/QOF";
import patientSurveys from "./modules/HQ/MoPF/PatientSurveys";
import actionPlans from "./modules/HQ/MoPF/ActionPlans";
import authModule from "./modules/auth";
import cleanerModule from "./modules/CS";
import adminModule from "./modules/AS";
import profileModule from "./modules/Profile";
import MAnewHireModule from "./modules/MA/newHire";
import MAgeneralModule from "./modules/MA";
import HQnewHireModule from "./modules/HQ/newHire";
import HQgeneralModule from "./modules/HQ";
import REnewHireModule from "./modules/RE/NewHire";
import REgeneralModule from "./modules/RE";
import RePoliciesModule from "./modules/RE/policies";
import ReLocums from "./modules/RE/locums";
import InterviewProcess from "./modules/RE/interviewProcess";
import Appraisals from "./modules/MA/appraisals";
import Trainings from "./modules/MA/staff-trainings";
import User from "./modules/USER";
import UserLocums from "./modules/USER/locums";
import GeneralPublicModule from "./modules/GeneralPublic";
import MAActionPlans from "./modules/MA/MOPF/ActionPlans";
import MAPatientSurveys from "./modules/MA/MOPF/PatientSurveys";
import hqModelDay from "./modules/HQ/MoPF/ModelDay";
import maModelDay from "./modules/MA/MOPF/ModelDay";
import hqComplianceCalender from "./modules/HQ/MoPF/ComplianceCalender";
import maComplianceCalender from "./modules/MA/MOPF/ComplianceCalender";
import hqCompliments from "./modules/HQ/MoPF/Compliments";
import maCompliments from "./modules/MA/MOPF/Compliments";
import hqComplaints from "./modules/HQ/MoPF/Complaints";
import maComplaints from "./modules/MA/MOPF/Complaints";
import PMLModule from "./modules/HQ/PML";
// Creates store modules
const store = createStore({
  modules: {
    auth: authModule,
    cleaner: cleanerModule,
    admin: adminModule,
    profile: profileModule,
    MAnewHire: MAnewHireModule,
    MAgeneralModule: MAgeneralModule,
    HQnewHire: HQnewHireModule,
    HQgeneralModule: HQgeneralModule,
    REnewHire: REnewHireModule,
    REgeneralModule: REgeneralModule,
    RePolicies: RePoliciesModule,
    GeneralPublicModule: GeneralPublicModule,
    ReLocums,
    InterviewProcess,
    Appraisals,
    Trainings,
    User,
    UserLocums,
    patientSurveys,
    actionPlans,
    hqQof,
    maQof,
    MAActionPlans,
    MAPatientSurveys,
    hqModelDay,
    maModelDay,
    hqComplianceCalender,
    maComplianceCalender,
    hqCompliments,
    maCompliments,
    hqComplaints,
    maComplaints,
    PMLModule,
  },
});

export default store;
