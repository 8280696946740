/** @format */

const ConfirmMedicalLetter = () =>
  import("../../../../pages/HQ/medicalLetters/confirmMedicalLetter.vue");

const ViewMedicalLetter = () =>
  import("../../../../pages/HQ/medicalLetters/viewMedicalLetterRequest.vue");

const MedicalLettersDash = () =>
  import("../../../../pages/HQ/medicalLetters/medicalLettersDash.vue");

const medicalLettersRoutes = [
  {
    path: "",
    name: "hq-medicalLettersDashboard",
    component: MedicalLettersDash,
  },
  {
    path: "/view-letter/:letterId",
    name: "viewMedicalLetter",
    component: ViewMedicalLetter,
  },
  {
    path: "/confirm-letter/:letterId",
    name: "confirmMedicalLetter",
    component: ConfirmMedicalLetter,
  },
];

export default medicalLettersRoutes;
