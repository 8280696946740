/** @format */

import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      medicalLetterRequests: [],
      medicalLetterRequest: {},
      signature: {},
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
